.sidebar{
  position: fixed;
  z-index: 7;
  top:0;
  left:0;
  bottom:0;
  width:200px;
  background: url('../images/bg.jpg') center;
  background-size: cover;
  @media(max-width: 1199px){
    width:50px;
  }

  .logo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    height: 70px;
    background: $color-black;
    @media(max-width: 1199px){
      padding: 20px 7px;
      height: 70px;
    }

    font-size: 10px;
    line-height: 12px;
    color:$color-white;
    >span{
      font-weight: 200;
      letter-spacing: 2px;
      @media(max-width: 1199px){
        display: none;
      }
    }
    >strong{
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 4px;
      @media(max-width: 1199px){
        font-size: 14px;
        line-height: 14px;
        margin-bottom: 0;
      }
    }

    img{
      max-width: 150px;
    }
  }


  .copyright{
    position: absolute;
    color:$color-light;
    opacity: .7;
    font-weight: 200;
    lefT:0;
    bottom:0;
    right:0;
    padding: 20px;
    font-size: 11px;
    text-align: center;
    @media(max-width: 1199px){
      display: none;
    }
  }


  .inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: auto;
    width: 100%;
    @media(max-width: 1199px){
      overflow: visible;
    }

    .nav{
      display: block;
      margin:0;
      padding:20px 0 40px 0;
      list-style: none;
      width: 100%;
      overflow-x: hidden;
      // background-color: rgba(0,0,0,.2);
      @media(max-width: 1199px){
        overflow: visible;
      }

      >.item{
        display: block;
        position: relative;
        border-left: 5px solid transparent;
        @include _transition();

        >.expand{
          display: block;
          padding: 5px;
          background: none;
          border: none;
          color:$color-white;
          position: absolute;
          top:10px;
          right:20px;
          cursor: pointer;

          @media(max-width: 1199px){
            display: none;
          }
        }

        &.title{
          padding:20px;
          font-size: 10px;
          letter-spacing: 2px;
          text-transform: uppercase;
          font-weight: 300;
          color:$color-light;
          @media(max-width: 1199px){
            display: none;
          }
        }

        >a{
          color: $color-light;
          padding: 10px 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          @include _transition();
          opacity: .7;
          @media(max-width: 1199px){
            padding: 13px 10px;
          }
          >span{
            margin-left: 10px;
            @media(max-width: 1199px){
              display: none;
            }
          }
          svg{
            width: 18px;
            height: 18px;
          }
        }

        &:hover{
          >a{
            text-decoration: none;
            color: $color-white;
            opacity: 1;
          }
        }

        >.children{
          display: block;
          height:0;
          opacity: 0;
          overflow: hidden;
          background: rgba($color-black,.1);
          padding: 0;
          @include _transition();
          @media(max-width: 1199px){
            position: absolute;
            top:0;
            left:45px;
            background: $color-black;
            display: none;
          }
          >.item{
            display: block;
            @include _transition();

            >a{
              color: $color-light;
              padding: 8px 20px 8px 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-size: 14px;
              font-weight: 200;
              opacity: .7;
              @include _transition();
              >span{
                margin-left: 10px;
                @media(max-width: 1199px){
                  margin-left: 0px;
                }
              }
              @media(max-width: 1199px){
                padding: 8px 20px 8px 20px;
              }
            }
          }
        }

        &:hover{
          >.children{
            @media(max-width: 1199px){
              display: block;
              position: absolute;
              top:0;
              left:45px;
              background: $color-black;
            }
          }
        }



        &.active{

          border-left-color:$color-accent;
          background:  rgba($color-black,.2);
          >a{
            color:$color-white;
            opacity: 1;
          }

          >.children{
            height:auto;
            opacity: 1;
            padding: 7px 0;
            overflow: visible;
          }
        }

      }

    }
  }
}
