.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 8;
  background: $color-white;
  top:0;
  right:0;
  left:200px;
  height: 70px;
  @media(max-width: 1199px){
    left:50px;
  }

  > div{
    &:nth-child(2){
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .username{
      margin-right: 20px;
      color:$color-black;
    }
    .logout{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      background: none;
      border: none;
      padding: 0;
      color:$color-accent;
      cursor: pointer;
    }
    .navicon{
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      background: none;
      border: none;
      padding: 0;
      color:$color-black;
      cursor: pointer;
    }
  }
}
