@import 'variables';
@import 'mixins';
@import 'buttons';
@import 'grid';
@import 'typography';
@import 'forms';
@import 'modal';
@import 'tabs';

@import 'comLayout';
@import 'comHeader';
@import 'comSidebar';
@import 'comLoading';
@import 'comPageHead';
@import 'comTable';


.tox .tox-editor-header{
  z-index: unset !important;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}

p {
  font-size: 16px;
  line-height: 24px;
  &.small{
    font-size: 12px;
    line-height: 16px;
  }
}

a {
  font-size: 16px;
  line-height: 24px;
}


.relative{
  position: relative;
}
.w-100{
  width:100%;
}


.card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background: $color-white;
  border-radius: 0;
  box-shadow: 0px 5px 10px rgba(0,0,0,.1);
}



.status{
  display: inline-block;
  padding: 0 4px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color:$color-white;
  background: $color-black;
  border-radius: 2px;

  &.error{
    background: $color-red;
  }
  &.success{
    background: $color-green;
  }

  &.s1{
    background: #3F84E5; // New request
  }
  &.s2{
    background: #D1788C; // To be called
  }
  &.s3{
    background: #C06B1B; // Waiting infos
  }
  &.s4{
    background: #7D6485; // Infos updated
  }
  &.s5{
    background: #BE5E1E; // Waiting for documents
  }
  &.s7{
    background: #B596B9; // Documents for notary
  }
  &.s7{
    background: #98B3E6; // Sent to notary
  }
  &.s8{
    background: #608F6B; // ZEFIX OK
  }
  &.s9{
    background: #BA4324; // Invoice sent
  }
  &.s10{
    background: #3F784C; // Payment OK
  }
  &.s11{
    background: #B20D30; // Canceled
  }
}


.step{
  position: absolute;
  top:-7px;
  left:-5px;
  bottom:-7px;
  z-index: -1;
  background: rgba(0,0,0,0);
  width:0%;

  &.s1{
    background: rgba(0,0,0,.03);
    width:0%;
  }
  &.s2{
    background: rgba(0,0,0,.06);
    width:20%;
  }
  &.s3{
    background: rgba(0,0,0,.09);
    width:40%;
  }
  &.s4{
    background: rgba(0,0,0,.12);
    width:60%;
  }
  &.s5{
    background: rgba(0,0,0,.15);
    width:80%;
  }
  &.s6{
    background: rgba(0,0,0,.18);
    width:100%;
  }
}


.pagination{
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin:20px 0;

  >li{
    display: block;
    margin:1px;

    &.disabled{
      display: none;
    }

    >a{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 10px;
      cursor: pointer;
      border-radius: 0px;
      font-weight: 300;
      text-transform: uppercase;

      &:hover{
        background: rgba($color-white, .1);
      }

      &:focus,
      &:active{
        background: $color-white;
        color:$color-black;
        outline: none;
      }
    }

    &.active{
      >a{
        background: $color-accent;
        color:$color-white;
        &:hover,
        &:focus,
        &:active{
          background: $color-accent;
          color:$color-black;
          outline: none;
        }
      }
    }
  }
}



.page-login{
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width:100%;
  background: url('../images/bg-login.jpg') bottom right;
  background-size: cover;

  .container{
    max-width: 800px;
  }

  .row{
    align-items: center;
  }

  .form{
    min-height: 300px;
    background: $color-white;
    box-shadow:0 5px 30px rgba(0,0,0,.2);
    border-radius: 4px;
    z-index: 2;
    position: relative;
    padding:40px;
    margin-right: -5px;

    h3{
      margin:0 0 30px 0;
      font-size: 16px;
      font-weight: 300;
    }
  }

  .promo{
    min-height: 250px;
    border-radius: 4px;
    background: rgba(255,255,255,.5);
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:40px 60px;

    h1{
      margin:0;
      padding:0;
      font-weight: 300;
      font-size: 30px;
      line-height: 36px;
      strong{
        font-weight: 900;
      }
    }

    p{
      margin:0;
    }
  }
}


.dropZone{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px dashed #aaa;
  padding: 30px;
  border-radius: 10px;
  cursor: pointer;

  &:hover{
    opacity: .7;
  }

  p{
    margin:10px 0 0 0;
    font-size: 12px;
  }
}



.notes{
  display: block;
  padding: 20px;
  font-size: 14px;
  background: rgba(0,0,0,.1);
  border-radius: 0 0 10px 10px;
  position: relative;
  &:before{
    position: absolute;
    display: block;
    content: "";
    top:-20px;
    left:0;
    border-top:20px solid transparent;
    border-right:20px solid transparent;
    border-left:20px solid rgba(0,0,0,.1);
  }
}


.publicForm{
  display: flex;
  flex:1;
  background: url('../images/lines.png') left no-repeat fixed;
  background-size: auto 100%;
  margin-top:-110px;
  margin-bottom: -40px;
  margin-left: -40px;
  margin-right: -40px;
  width:calc(100% + 80px);
  padding: 40px 0;
}


.alert{
  display: block;
  text-align: center;
  padding: 30px;
  color:$color-white;

  &.alert-danger{
    background: $color-red;
  }

  &.alert-success{
    background: $color-green;
  }
}


button.link{
  outline: none;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
}
button.link:hover{
  color:#3F84E5;
}
button.link svg{
  width: 12px;
  height:12px;
  margin-right:5px;
}



.sidebar .inner .nav > .item > .children > .item.active > a{
  opacity: 1;

}
